<template>
  <div id="hfa-services">
    <div class="header">
      <div class="breadcrumbs">
        <div class="item active">
          <span>STEP 1</span>
          <div class="circle"></div>
        </div>
        <div class="item active">
          <span>STEP 2</span>
          <div class="circle">
            <div class="line"></div>
          </div>
        </div>
        <div class="item active">
          <span>STEP 3</span>
          <div class="circle">
            <div class="line"></div>
          </div>
        </div>
      </div>
      <h1>Select your HFA Affiliation Services</h1>
      <a class="pdf-link">PDF Agreement</a>
    </div>

    <div class="body">
      <div class="message" v-if="isSuccess">
        <p>Thank you for completing our HFA Affiliate Application!</p>

        <p>
          Your application has been successfully submitted. Please check your
          inbox for a confirmation of your payment.<br />We will begin
          processing and a member of our team will be in touch. Please allow up
          to 10 business days for processing.
        </p>
      </div>

      <div class="message" v-if="!isSuccess">
        <p>ERROR MESSAGE PLACEHOLDER</p>
      </div>

      <router-link :to="{ name: 'home' }" class="button primary shadow submit"
        >CLOSE</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HfaServicesCallback",
  data: function() {
    return {};
  },
  computed: {
    isSuccess: function() {
      return this.$route.params.status == "success";
    }
  }
};
</script>

<style lang="scss">
#hfa-services {
  @media (min-width: $desktop) {
    margin-left: -50px;
  }

  .header {
    background-color: $blue;
    color: #fff;
    padding: 1em;

    h1 {
      color: #fff;
      font-family: "Fjalla One", sans-serif;
      font-size: 1.2em;
      margin: 0;
    }
    .pdf-link {
      color: #fff;
      text-decoration: underline;
      font-size: 0.8em;
      font-family: "Antic Slab", serif;
    }
    .breadcrumbs {
      display: none;

      @media (min-width: $desktop) {
        display: flex;
      }

      .item {
        text-align: center;
        margin: 0 10px;
        font-size: 0.8em;

        span {
          display: block;
          margin-bottom: 5px;
        }
        .circle {
          display: inline-block;
          background-color: #fff;
          border-radius: 50%;
          width: 1.5em;
          height: 1.5em;
        }

        &.active {
          .circle {
            background-color: $yellow;
            position: relative;

            .line {
              background-color: $yellow;
              height: 1px;
              width: 40px;
              position: absolute;
              top: 50%;
              transform: translate(-100%, -50%);
            }
          }
        }
      }
    }
  }
  .body {
    padding: 10px;

    @media (min-width: $desktop) {
      padding: 50px;
    }

    .button.submit {
      display: block;
      width: 200px;
      margin: 50px auto 0;
      font-size: 1.4em;
    }
  }
}
</style>
